<template>
  <div class="center-noflex">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  props:["title","subtitle"],
  name: "titles",
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 34px;
  font-weight: 100;
  margin-bottom: 3px;
}
.subtitle {
  font-weight: 400;
  font-size: 12px;
}
</style>
