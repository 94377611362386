<template>
	<div class="page">
		<div class="pageHeader center-noflex" :style='{"background-image": "url("+backgrond_img+")"}'>
			<div class="title">{{cate_title}}</div>
			<div class="subtitle">{{cate_content}}</div>
		</div>
		<div class="pagecontent">
			<div class="center">
				<el-carousel height="400px" indicator-position="none" style="width:1200px">
					<el-carousel-item v-for="(item, index) in Topimage" :key="index">
						<div class="pagecontent-lunIMage" @click="imgUrl(index)">
							<img :src="item.image" alt="" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<titles title="品牌活动" subtitle="Brand activities" style="margin-top: 70px; margin-bottom: 40px"></titles>
			<div class="BrandList">
				<div v-for="(item, index) in Brand" :key="index" @click="pin_detail(item.id)" class="Branditem">
					<img :src="item.image" alt="" style="width: 100%; height: 256px" />
					<div class="Brandtext">{{ item.title }}</div>
				</div>
			</div>
			<titles title="活动信息" subtitle="Activity information" style="margin-top: 70px; margin-bottom: 40px"></titles>
			<div class="activitylist">
				<div v-for="(item, index) in activity" :key="index" @click="hd_detail(item.id)" class="activityitem">
					<img :src="item.image" alt="" style="height: 223px" />
					<div class="activity-content">
						<div class="activityTitle">{{ item.title }}</div>
						<div class="flex activitytagList">
							<div v-for="(items, indexs) in item.tagList" :key="indexs" class="item-tag">
								{{ items }}
							</div>
						</div>
						<div class="activitytext">
							<div>活动时间：{{ item.time }}</div>
							<div class="flex">
								<div style="white-space: nowrap">活动地点：</div>
								<div>{{ item.address }}</div>
							</div>
						</div>
						<div class="end">
							<div v-if="item.status == 1" class="activitybottombutton" style="border-color: rgb(0, 118, 254); color: rgb(0, 118, 254)">
								报名中
							</div>
							<div v-if="item.status == 2" class="activitybottombutton" style="border-color: rgb(226, 226, 226); color: rgb(226, 226, 226)">
								已结束
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer">
				<div>
					<div class="footertitle">
						精彩视频 <span style="font-size: 12px">Wonderful video</span>
					</div>
					<div style="width: 600px">
						<el-carousel height="350px" @change="leftchange" :autoplay="false" indicator-position="none">
							<el-carousel-item v-for="(item, index) in LeftvideoList" :key="index">
								<div class="videoItem" v-if="item.isvideo == 2">
									<!-- <video controls :ref="'Leftvideo' + item.id" autostart="false" type="application/x-mplayer2" src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/mda-kcuyvjmghq17rd17.mp4" loop></video> -->
									<video id="Leftvideo"  :ref="'Leftvideo' + item.id" type="application/x-mplayer2" muted autostart="false" style="width:100%;" loop>
										<source :src="item.url" >
									</video>
									<div class="video-img" v-show="leftvideoId!=item.id" @click="leftchange(index)"></div>
								</div>
								<div class="videoItem" v-if="item.isvideo == 1">
									<img :src="item.url" alt="" />
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div>
					<div class="footertitle">
						正在直播 <span style="font-size: 12px">It's live</span>
					</div>
					<div style="width: 600px">
						<el-carousel height="350px" indicator-position="none" :autoplay="false" @change="videochange">
							<el-carousel-item v-for="(item, index) in videoList" :key="index">
								<div class="videoItem" v-if="item.isvideo == 2" @click="videochange(index)">
									<!-- <video :ref="'video' + item.id" controls type="application/x-mplayer2" autostart="false" src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/mda-kcuyvjmghq17rd17.mp4" loop></video> -->
									<video id="'video'"  :ref="'video' + item.id" type="application/x-mplayer2" autostart="false" muted style="width:100%;" >
										<source :src="item.url">
									</video>
									<div class="video-img" v-show="videoId!=item.id"></div>
								</div>
								<div class="videoItem" v-if="item.isvideo == 1">
									<img :src="item.url" alt="" />
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import titles from "components/title.vue";
	export default {
		components: {
			titles
		},


		data() {
			return {
				videoId: 0,
				videotxt: 0,
				leftvideoId: 0,
				Topimage: [{
						image: require("@/assets/test/test_Icon15.png"),
					},
					{
						image: require("@/assets/test/test_Icon15.png"),
					},
					{
						image: require("@/assets/test/test_Icon15.png"),
					},
				],
				kaiImg: 'http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/kaishi.png',
				Brand: [{
						img: require("@/assets/test/test_Icon14.png"),
						text: "中国好美文化IP开发者大会",
					},
					{
						img: require("@/assets/test/test_Icon14.png"),
						text: "文化IP博览会",
					},
					{
						img: require("@/assets/test/test_Icon14.png"),
						text: "文化IP复兴计划",
					},
				],
				activity: [{
						img: require("@/assets/test/test_Icon16.png"),
						title: "活动名称活动名称活动名称",
						tagList: ["活动状态"],
						ctime: "2021年2月12日",
						area: "江苏省南京市江宁区上元大街155号滨河国际街区三楼会议室",

						status: "1",
						text: "中国好美文化IP开发者大会",
					},
					{
						img: require("@/assets/test/test_Icon16.png"),
						title: "活动名称活动名称活动名称",
						tagList: ["活动状态"],
						ctime: "2021年2月12日",
						area: "江苏省南京市江宁区上元大街155号滨河国际街区三楼会议室",
						status: "1",
						text: "文化IP博览会",
					},
					{
						img: require("@/assets/test/test_Icon16.png"),
						title: "活动名称活动名称活动名称",
						tagList: ["活动状态"],
						ctime: "2021年2月12日",
						area: "江苏省南京市江宁区上元大街155号滨河国际街区三楼会议室",
						status: "0",
						text: "文化IP复兴计划",
					},
				],
				videoList: [{
						id: 1,
						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},

					{
						id: 2,

						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},
					{
						id: 3,

						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},
					{
						id: 4,

						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},
				],
				backgrond_img: require("@/assets/about/about_banner.png"),
				cate_title: 'IP活动',
				cate_content: '汇聚国内优质文化IP版权提供IP交易与授权',
				LeftvideoList: [{
						id: "1",
						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},
					{
						id: "2",
						isvideo: 2, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/mda-kcuyvjmghq17rd17.mp4",
						select: false,
					},
					{
						id: "3",
						isvideo: 2, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/mda-kcuyvjmghq17rd17.mp4",
						select: false,
					},
					{
						id: "4",
						isvideo: 1, //2视频1图片
						url: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/aaaa.png",
						select: false,
					},
				],
			};
		},
		async created() {
			const that = this;
			const {
				data
			} = await this.$api({
				c: "index",
				a: "activity",
			});
			//console.log(data);
			var arr = data.arr;
			that.Topimage = arr.banner;
			that.Brand = arr.product;
			that.activity = arr.massage;
			that.LeftvideoList = arr.huigu;
			that.videoList = arr.video;
			this.cate_title = arr.IP_banner.title;
			this.cate_content = arr.IP_banner.content;
			this.backgrond_img = arr.IP_banner.image;
			//console.log(this.cate_content);
		},
		methods: {
			imgUrl(index) {
				//console.log(index);
				var links = this.Topimage[index].link;
				links = links.split('#');
				//console.log(links);
				this.$router.push(links[1]);
			},
			pin_detail(id){
				this.$router.push({
					path: "/index/PinDetail",
					query: {
						id: id,
					},
				});
			},
			hd_detail(id) {
				this.$router.push({
					path: "/index/activityDetail",
					query: {
						id: id,
					},
				});
			},
			leftchange(e) {
				// this.LeftvideoList.forEach((item) => {
				// 	if (item.isvideo == 2) {
				// 		this.$refs["Leftvideo" + item.id][0].pause();
				// 	}
				// });
				var paused = this.$refs["video" + this.LeftvideoList[e].id][0].paused;
				var play = this.$refs["video" + this.LeftvideoList[e].id][0].play();
				//console.log(paused, "--11111---" + this.videoList[e].id);
				console.log(play);
				if (paused) {
					//console.log(this.LeftvideoList[e].isvideo + '----11111')
					if (this.LeftvideoList[e].isvideo == 2) {
						this.leftvideoId = this.LeftvideoList[e].id;
						this.$refs["Leftvideo" + this.LeftvideoList[e].id][0].play();
					}
				} else {
					this.videoId = 0;
					this.$refs["Leftvideo" + this.LeftvideoList[e].id][0].pause();
				}
			},
			videochange(e) {
				var id = this.videoList[e].id
				console.log(id);
				// this.videoList.forEach((item) => {
				// 	if (item.isvideo == 2) {
				// 		//console.log(this.$refs["video" + item.id][0]);
				// 		this.$refs["video" + item.id][0].pause();
				// 	}
				// });
				var paused = this.$refs["video" + this.videoList[e].id][0].paused;
				var play = this.$refs["video" + this.videoList[e].id][0].play();
				//console.log(paused, "--11111---" + this.videoList[e].id);
				console.log(play);
				if (paused) {
					//console.log(this.videoList[e].isvideo + '----11111')
					if (this.videoList[e].isvideo == 2) {
						this.videoId = this.videoList[e].id;
						this.$refs["video" + this.videoList[e].id][0].play();
					}
				} else {
					this.videoId = 0;
					this.$refs["video" + this.videoList[e].id][0].pause();
				}


			},

		},
	};
</script>

<style lang="scss" scoped>
	.page {
		background: rgb(248, 248, 248);
	}

	.pageHeader {
		height: 400px;
		line-height: 60px;
		// background: rgb(33, 107, 229);
        background-size: 100% 100%; 
		.title {
			color: #ffffff;
			font-weight: 900;
			font-size: 30px;
			margin-bottom: 5px;
		}

		.subtitle {
			font-weight: 100;
			font-size: 12px;
			color: #ffffff;
		}
	}

	.pagecontent {
		padding: 10px 0px 68px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.BrandList {
		width: 1200px;
		margin: 0 auto;
		display: flex;

		// column-gap: 15px;
		justify-content: space-between;

		.Branditem {
			position: relative;
			width: 385px;

			img {
				object-fit: cover;
			}
		}

		.Brandtext {
			position: absolute;
			width: 100%;
			background: rgba(0, 0, 0, 0.5);
			color: #ffffff;
			padding: 9px 17px;
			bottom: 6px;
		}
	}

	.activitylist {
		width: 1050px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		// column-gap: 15px;
		.activityitem {
			width: 340px;
			background: #ffffff;
			border-bottom: 1px solid #ffffff;
			transition: all 0.6s;

			&:hover {
				border-bottom: 1px solid rgb(0, 118, 254);
			}

			img {
				width: 100%;
			}
		}

		.item-tag {
			border: 1px solid rgb(0, 118, 254);
			color: rgb(0, 118, 254);
			padding: 2px 10px;
			margin-right: 4px;
			border-radius: 100px;
			font-size: 12px;
		}

		.activitytext {
			line-height: 25px;
			color: #676767;
			white-space: pre-wrap;
			font-size: 13px;
		}

		.activitytagList {
			margin: 7px 0px 10px;
		}

		.activityTitle {
			font-weight: 300;
			font-size: 22px;
		}

		.activity-content {
			padding: 20px 15px 23px 23px;
		}

		.activitybottombutton {
			margin-top: 25px;
			padding: 6px 60px;
			border: 1px solid transparent;
			font-size: 12px;
		}
	}

	.footer {
		width: 1250px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		// column-gap: 20px;
		margin-top: 50px;

		.footertitle {
			color: #676767;
			font-size: 20px;
			margin-bottom: 32px;
		}
	}

	.videoItem {
		display: relative;

		video {
			height: 350px;
			width: 600px;
			flex: 1;
			display: inline-block;
			vertical-align: baseline;
		}

		img {
			height: 350px;
			width: 600px;
			flex: 1;
		}
	}

	.pagecontent-lunIMage {
		img {
			object-fit: cover;
			width: 1380px;
			height:350px;
		}
	}

	.video-img {
		position: absolute;
		top: 40%;
		left: 40%;
		// width: 100%;
		width: 80px;
		height: 80px;
		z-index: 999;
		background: url(http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/kaishi.png) no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}
</style>
